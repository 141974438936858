<template>
  <div class="adminPage">
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive"> </router-view>
    </keep-alive> -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.adminPage {
  height: 100%;
  overflow-x: auto;
}
</style>
